.page-inn--projects {
  padding-top: 400px;
  padding-bottom: 80px;
}
.projects-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 380px;
}
.projects__title {
  font-family: $font-os-b;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #00755a;

  @include max(1000) {
    font-size: 20px;
  }
}
.projects__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.projects__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33.33%;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: center;

  @include max(1000) {
    width: 50%;
  }
  @include max(500) {
    width: 100%;
  }

  &:hover {
    text-decoration: none;
    .projects__img {
      img {
        transform: scale(1.05);
      }
      &::before {
        top: 5px;
        bottom: 5px;
      }
    }
  }
}
.projects__img {
  position: relative;
  width: 90%;
  max-width: 208px;
  margin-bottom: 10px;
  &::before {
    position: absolute;
    top: 20px;
    right: -8px;
    bottom: 20px;
    left: -8px;
    width: auto;
    margin: auto;
    content: '';
    transition: .3s;
    border: 3px solid #ffd400;
  }
  img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    transition: transform .5s;
  }
}
.projects__img--inn {
  overflow: hidden;
}
.projects__text {
  span {
    font-family: $font-os-b;
    font-size: 14px;
    text-transform: uppercase;
    color: #00755a;
  }
}

.projects-popup__layer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 21, 15, .6);
}
.projects-popup {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  display: none;
  width: 90%;
  max-width: 870px;
  transform: translate(-50%, -50%);
  background-color: #fff;

  &::before {
    position: absolute;
    z-index: -1;
    top: 15px;
    right: -15px;
    bottom: -15px;
    left: 15px;
    margin: auto;
    content: '';
    border: 2px solid #ffd400;
  }
}
.projects-popup__inn {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh;
}
.projects-popup__top {
  position: relative;
  padding: 30px 25px 45px 25px;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  &::before {
    position: absolute;
    bottom: 17px;
    left: -8px;
    width: 119px;
    height: 6px;
    content: '';
    background-color: #ffd400;
  }
}
.projects-popup__title {
  font-family: $font-os-b;
  font-size: 30px;
  margin: 0;
  color: #007659;

  @include max(550) {
    font-size: 24px;
  }
}
.projects-popup__bottom {
  padding: 35px 25px;
  background-color: #fff;
  p {
    font-size: 14px;
  }
}
