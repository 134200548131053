.about__btn-row {
	position: relative;
	display: inline-block;
	margin: 15px 0;

	@include max(1000) {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	&::before {
		position: absolute;
		top: 16px;
		left: 0;
		width: 100%;
		height: 2px;
		content: '';
		background-color: #ffd400;

		@include max(1000) {
			display: none;
		}
	}
}

.about__btn {
	font-size: 12px;
	line-height: 12px;
	margin-right: 30px;
	margin-bottom: 15px;
	text-align: center;
	color: #fff;

	@include max(1100) {
		margin-right: 5px;
	}
	@include max(1000) {
		min-width: 250px;
		margin-right: 0;
	}
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		color: #fff;
		&::after {
			background-color: #007454;
		}
	}
	&::before {
		border: 2px solid #007454;
	}
	&::after {
		background-color: #007454;
	}
}

.about-sec__header {
	position: relative;
	margin-bottom: 15px;
	&::before {
		position: absolute;
		top: 9px;
		left: 0;
		width: 100%;
		height: 7px;
		content: '';
		background-color: #ffd400;
	}
}

.about-sec__title {
	font-family: $font-os-b;
	font-size: 18px;
	position: relative;
	display: inline-block;
	padding-right: 10px;
	text-transform: uppercase;
	background-color: #fff;
}

.about-license__list {
	display: flex;
	flex-wrap: wrap;
	margin-right: -2%;
	margin-bottom: 15px;

	@include max(1000) {
		margin-right: -3.33%;
	}
}

.about-license__item {
	width: 23%;
	margin: 0 2% 15px 0;
	outline: none;
	display: flex;
	flex-direction: column;

	@include max(1000) {
		width: 30%;
		margin: 0 3.33% 15px 0;
	}
}
.about-license__img {
	margin-bottom: 20px;
	img {
		display: block;
		width: 100%;
	}
}

.about-advantages__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.about-advantages__item {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 25%;
	margin-bottom: 30px;
	padding: 0 15px;
	text-align: center;

	@include max(1000) {
		width: 33.33%;
		padding: 0 5px;
	}
	@include max(500) {
		width: 50%;
	}
}

.about-advantages__icon {
	display: flex;
	align-items: center;
	flex: none;
	justify-content: center;
	width: 80px;
	height: 80px;
	margin-bottom: 10px;
	border: 4px solid #00755a;
	svg {
		width: 32px;
		height: 32px;

		fill: #ffd400;
	}
}

.about-advantages__text {
	span {
		font-family: $font-pt;
		font-size: 13px;
		line-height: 1;
		text-transform: uppercase;
		color: #02755a;
	}
}

.about-sec__slider {
	width: calc(100% - 50px);
	margin: -20px auto 10px;
}

.about-sec__slider-item {
	img {
		display: block;
		max-width: 95%;
	}
}

.about-sec__news-list {
	display: flex;
	flex-wrap: wrap;
}

.about-sec__news-item {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 33.33%;
	margin-bottom: 20px;
	padding: 0 12px;
	text-align: center;
	text-decoration: none;

	@include max(1000) {
		width: 50%;
	}
	@include max(500) {
		width: 100%;
	}
	&:hover {
		text-decoration: none;
		.about-sec__news-pic::before {
			opacity: .5;
		}
		.about-sec__news-pic::after {
			width: calc(100% + 30px);
		}
	}
}

.about-sec__news-pic {
	position: relative;
	width: 95%;
	&::after {
		position: absolute;
		top: 15px;
		right: -15px;
		bottom: -13px;
		left: -15px;
		width: 0;
		margin: auto;
		content: '';
		transition: width .3s;
		border: 3px solid #ffd400;
	}

	&::before {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		content: '';
		transition: opacity .5s;
		opacity: 0;
		background-color: #03745a;
	}
	img {
		position: relative;
		z-index: 1;
		display: block;
		width: 100%;
	}
}

.about-sec__news-date {
	position: relative;
	width: 100%;
	margin-bottom: 10px;
	span {
		font-family: $font-os-b;
		font-size: 12px;
		position: relative;
		z-index: 1;
		display: block;
		padding: 3px;
		color: #fff;
		background-color: #03745a;
	}
}

.about-sec__news-text {
	font-family: $font-os-b;
	font-size: 14px;
	text-transform: uppercase;
	color: #002219;

	@include max(1024) {
		font-size: 12px;
	}
}
