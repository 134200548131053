.aside-wrap {
  //transform: translateY(-25px);
  position: fixed;
  //position: relative;
  z-index: 10;
  top: 0;
  height: 100%;
  transition: transform .5s;

  @include max(700) {
    //position: absolute;
    top: 23px;
    right: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 60px;
    transition: .5s;
    &.active {
      height: 100vh;
    }
    //  transform: translate(-240px, 0);
    //  &.active {
    //    transform: translate(0px, 0);
    //  }
  }
}
.aside-open {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100px;
  cursor: pointer;
  background-color: #ffd400;

  span {
    font-family: $font-pt-b;
    font-size: 18px;
    display: inline-block;
    transform: rotate(-90deg);
    text-transform: uppercase;
    color: #007454;
  }
}
.aside {
  position: relative;
  z-index: 10;
  display: flex;
  flex: none;
  flex-direction: column;
  width: 240px;
  min-height: 500px;
  padding: 40px 0;
  background-color: #fff;
  background-image: url('../img/bg-aside.jpg');
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: contain;

  @include max(700) {
    width: 100%;
    height: 100vh;
    padding-top: 70px;
    text-align: center;
  }
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 89px;
    height: 93px;
    content: '';
    background-image: url('../img/decor-aside.jpg');
    background-repeat: no-repeat;
    background-position: 0 100%;
  }
}

.aside-logo {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 30px;

  @include max(700) {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 151px;
    height: 34px;
    padding: 0;
    background-color: #347;
  }

  img {
    max-width: 100%;
  }
}
.aside-menu__list {
  margin: 0;
  padding: 0;
}
.aside-menu__item {
  position: relative;
  padding: 7px 20px;
  list-style: none;
  &::before {
    position: absolute;
    top: 15px;
    right: 0;
    left: 0;
    width: 100%;
    height: 6px;
    content: '';
    transition: background-color .5s;
    background-color: #fff;
  }
  &.active,
  &:hover {
    &::before {
      background-color: #007454;

      @include max(700) {
        background-color: transparent;
      }
    }
    .aside-menu__link {
      @include max(700) {
        color: #007454;
      }
    }
  }
}
.aside-menu__link {
  font-family: $font-os-b;
  font-size: 16px;
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
  color: #002219;
  background-color: #fff;
  &:hover {
    text-decoration: none;
    color: #002219;
  }
}
.aside-menu__item--parent {
  &:hover {
    .aside-menu__sublist {
      transform: scale(1);
    }
  }
}
.aside-menu__sublist {
  position: absolute;
  top: 0;
  left: 100%;
  width: 240px;
  margin: 0;
  padding: 15px 20px 10px 20px;
  transition: transform .5s;
  transform: scale(0);
  background-color: #007454;

  @include max(700) {
    display: none !important;
  }
}
.aside-menu__subitem {
  padding: 8px 0;
  list-style: none;
}
.aside-menu__sublink {
  font-size: 14px;
  line-height: 1;
  transition: color .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  &:hover {
    color: #ffd400;
  }
}

.aside-lang {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70px;
  height: 70px;
  margin: 70px auto 170px;
  border: 1px solid rgba(0, 118, 91, .3);
}
.aside-lang__item {
  z-index: 2;
  flex: none;
  width: 22px;
  height: 16px;
  cursor: pointer;
  background-image: url('../img/flags.jpg');
  &:hover {
    & + .aside-lang__item--decor {
      opacity: 1;
    }
  }
}
.aside-lang__item--ru + .aside-lang__item--decor {
  left: -20px;
}
.aside-lang__item--eng + .aside-lang__item--decor {
  right: -20px;
}
.aside-lang__item--decor {
  position: absolute;
  top: 21px;
  width: 15px;
  height: 25px;
  transition: opacity .5s;
  opacity: 0;
  border: 1px solid rgba(0, 118, 91, .3);
}
.aside-lang__item--ru {
  margin-left: -11px;
  background-position: 0 100%;
  &.active {
    background-position: 0 0;
  }
}
.aside-lang__item--eng {
  margin-right: -11px;
  background-position: 100% 100%;
  &.active {
    background-position: 100% 0;
  }
}
.aside-lang__text {
  font-size: 14px;
  line-height: 18px;
  width: 35px;
  height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #007454;
}
