.service {
  .page-inn {
    padding: 0 0 35px 0;
  }
}
.service-tab {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.service-tab__item {
  display: flex;
  align-items: center;
  width: 33.33%;
  min-height: 90px;
  padding: 25px;
  transition: all .5s;
  border-right: 1px solid rgba(143, 143, 143, .3);
  border-bottom: 1px solid rgba(143, 143, 143, .3);
  background-color: #fff;
  box-shadow: 0 0 20px rgba(3, 116, 90, 0);

  @include max(1000) {
    width: 50%;
  }
  @include max(550) {
    width: 100%;
    min-height: 0;
    padding: 15px 25px 13px 25px;
  }
  &:hover {
    text-decoration: none;
    background-color: #ffd400;
    box-shadow: 0 0 20px rgba(3, 116, 90, .1);
    span {
      color: #03745a;
    }
  }
  &:first-child {
    background-color: #03745a;
    box-shadow: inset 0 0 21px rgba(6, 70, 44, .38);
    span {
      font-family: $font-pt-b;
      font-size: 22px;
      letter-spacing: .22px;
      text-transform: uppercase;
      color: #fff;

      @include max(1000) {
        font-size: 20px;
      }
    }
  }
  span {
    font-family: $font-pt-b;
    font-size: 17px;
    letter-spacing: .17px;
    text-transform: uppercase;
    color: #3f3f3f;
  }
}
.service-body {
  padding: 0 40px;
}
.service-body__title {
  font-family: $font-os;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  margin-top: 30px;
  text-transform: uppercase;
  color: #00755a;
  &::before {
    position: absolute;
    top: 15px;
    left: -31px;
    width: 21px;
    height: 6px;
    content: '';
    background-color: #ffd400;
  }
}
#scroll2top {
  display: none;
}
.scroll2top {
  position: fixed;
  right: 0;
  bottom: 20px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: auto;
  cursor: pointer;
  transition: border-color .5s;
  transform: translate(650px);
  border: 2px solid #fff;

  @include max(1400) {
  transform: translate(0);
    right: 20px;
    bottom: 20px;
    left: auto;
    border: 2px solid #ffd400;
  }
  @include max(1180) {
    right: 5px;
    bottom: 40px;
  }
  @include max(1000) {
    width: 40px;
    height: 40px;
    right: 20px;
  }
  @include max(700) {
    right: 10px;
  }
  &:hover {
    border-color: #ffd400;
    svg {
      fill: #ffd400;
    }
  }
  svg {
    width: 21px;
    height: 34px;
    transition: fill .5s;
    transform: rotate(-90deg);

    fill: #fff;
    @include max(1400) {
      fill: #ffd400;
    }
    @include max(1000) {
      width: 12px;
      height: 17px;
    }
  }
}
