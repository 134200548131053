.parallax {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/parallax/bg-parallax.jpg');
  background-repeat: no-repeat;
  background-position: 50%  50%;
  background-size: cover;
}
.parallax__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  @include max(1024) {
    display: none !important;
  }
}
.parallax__layer-inn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 110%;
  transform: translate(-5%, 0);
  background-repeat: no-repeat;
  background-position: 50% 0;
}
.parallax__layer--top {
  .parallax__layer-inn {
    background-image: url('../img/parallax/parallax-01.png');
  }
}
.parallax__layer--middle {
  .parallax__layer-inn {
    background-image: url('../img/parallax/parallax-02.png');
  }
}
.parallax__layer--bottom {
  .parallax__layer-inn {
    background-image: url('../img/parallax/parallax-03.png');
  }
}
