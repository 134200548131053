.breadcrumbs {
  padding-top: 10px;
}
.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  .breadcrumbs__item:last-child {
    .breadcrumbs__link {
      text-decoration: none;
      pointer-events: none;
    }
  }
}
.breadcrumbs__item {
  position: relative;
  list-style: none;
  &::after {
    font-size: 12px;
    margin: 0 7px 0 4px;
    content: '/';
    color: #fff;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
.breadcrumbs__link {
  font-family: $font-pt;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: underline;
  color: #fff;
  &:hover {
    text-decoration: none;
  }
}
