.video {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url('../img/bg-video.jpg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.hero-video {
  width: 100%;
  height: 100vh;

  object-fit: cover;
  @include max(700) {
    display: none;
  }
}
