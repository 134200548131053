.main {
  position: relative;
  padding-top: 25px;
}

.index {
  min-height: calc(100vh - 30px);
  display: flex;
  align-items: center;
  padding-left: 230px;
  @include max(700) {
    padding-top: 60px;
    padding-left: 0;
  }
}
.hero {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 20px 70px;

@include max(1000) {
    padding-left: 40px;
  }

@include max(700) {
    padding-left: 0;
  }

  p {
    font-size: 16px;
    color: #fff;
  }
}

.hero-text {
  position: relative;
  &::before {
    position: absolute;
    top: -10px;
    left: -20px;
    width: 40px;
    height: 40px;
    content: '';
    border: 6px solid #ffd400;
  }
}
.hero__title {
  font-family: $font-pt-b;
  font-size: 40px;
  position: relative;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;

@include max(425) {
    font-size: 30px;
  }
}
.hero__subtitle {
  font-family: $font-pt-b;
  font-size: 27px;
  text-transform: uppercase;
  color: #fff;

@include max(425) {
    font-size: 20px;
  }
}
.index-btn {
  margin-top: 30px;
}
.hero-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 680px;
  padding: 80px 0 30px 0;

@include max(500) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.hero-footer__item {
  position: relative;
  display: flex;
  align-items: center;
  flex: none;
  flex-direction: column;
  text-align: center;

@include max(500) {
    width: 35%;
    margin-bottom: 20px;
  }

  &:last-child {
    &::before {
      display: none;
    }
  }
  &:nth-child(2) {
    &::before {
      left: 155px;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 130px;
    width: 85px;
    height: 4px;
    margin: auto;
    content: '';
    background-color: #fff;

@include max(1000) {
      display: none;
    }
  }
}
.hero-footer__top,
.hero-footer__bottom {
  font-family: $font-pt;
  font-size: 13px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #fff;
}
.hero-footer__middle {
  font-family: $font-pt-b;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 81px;
  margin-bottom: 5px;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  border: 4px solid #fff;
}
