@charset "UTF-8";
@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans.woff2") format("woff2"), url("../fonts/OpenSans.woff") format("woff"); }

@font-face {
  font-family: OpenSans-Bold;
  src: url("../fonts/OpenSans-Bold.woff2") format("woff2"), url("../fonts/OpenSans-Bold.woff") format("woff"); }

@font-face {
  font-family: PTSans-Regular;
  src: url("../fonts/PTSans-Regular.woff2") format("woff2"), url("../fonts/PTSans-Regular.woff") format("woff"); }

@font-face {
  font-family: PTSans-Bold;
  src: url("../fonts/PTSans-Bold.woff2") format("woff2"), url("../fonts/PTSans-Bold.woff") format("woff"); }

*,
*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  margin: 0;
  padding: 0; }

html {
  font-family: "OpenSans", sans-serif;
  font-size: 14px;
  line-height: 1.38;
  color: #585858; }

body {
  display: flex;
  flex-direction: column;
  min-width: 320px; }

body.no-scroll {
  overflow: hidden; }

body.fix {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 15px 0; }

h1 {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase; }

h2 {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: #00755a; }

h3 {
  font-family: "OpenSans", sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  color: #00755a; }

h4 {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #074f02; }

h5 {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #3b3b3b; }

h6 {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #007454; }

p {
  font-size: 16px; }
  p strong {
    font-family: "PTSans-Bold", sans-serif; }

a {
  transition: color .5s;
  text-decoration: none;
  color: #ffd400; }
  a:hover {
    text-decoration: underline;
    color: #ffd400; }

ol li,
ul li {
  padding: 3px 0; }

ul li {
  list-style-image: url("../img/ul.png"); }

ol {
  list-style: none;
  counter-reset: li; }
  ol > li::before {
    content: counter(li) ". ";
    counter-increment: li; }
  ol li::before {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #007454; }

input,
textarea,
select,
button {
  border-radius: 0 !important;
  outline: none; }

input {
  padding: 3px 10px 5px 10px;
  color: #585858;
  border: 1px solid #585858; }
  input::placeholder {
    opacity: 1;
    /* Chrome/Opera/Safari */
    /* Firefox 19+ */
    /* IE 10+ */
    /* Firefox 18- */ }
    input::placeholder::-webkit-input-placeholder {
      opacity: 1;
      color: #585858; }
    input::placeholder::-moz-placeholder {
      opacity: 1;
      color: #585858; }
    input::placeholder:-ms-input-placeholder {
      opacity: 1;
      color: #585858; }
    input::placeholder:-moz-placeholder {
      opacity: 1;
      color: #585858; }

textarea {
  resize: none; }

input.error,
textarea.error {
  border-color: #ed1b24 !important;
  background: #fbd1d3 !important; }

svg {
  display: block;
  overflow: visible; }

.main {
  position: relative;
  flex: 1 0 auto; }

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px; }
  @media screen and (max-width: 768px) {
    .container {
      padding: 0 20px; } }
  @media screen and (max-width: 425px) {
    .container {
      padding: 0 10px; } }

.main-container {
  display: flex;
  align-items: flex-start; }

.header,
.page-header,
.footer {
  flex: 0 0 auto; }

.animated {
  animation-duration: 1.3s; }
  @media screen and (max-width: 700px) {
    .animated {
      transition-property: none !important;
      transform: none !important;
      animation: none !important; } }

.popup__layer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 21, 15, 0.6); }

.popup {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  display: flex;
  width: 90%;
  max-width: 840px;
  transform: translate(-50%, -50%);
  background-color: #fff; }
  .popup::before {
    position: absolute;
    z-index: -1;
    top: 15px;
    right: -15px;
    bottom: -15px;
    left: 15px;
    margin: auto;
    content: '';
    border: 2px solid #ffd400; }

.popup__close {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 20px;
  height: 20px;
  cursor: pointer; }
  @media screen and (max-width: 700px) {
    .popup__close {
      right: 0; } }
  .popup__close:hover svg {
    fill: #ffd400; }
  .popup__close svg {
    width: 20px;
    height: 20px;
    transition: fill .5s;
    fill: #fff; }

.popup__left {
  width: 50%;
  padding: 35px 30px;
  background-color: #fff; }
  @media screen and (max-width: 700px) {
    .popup__left {
      width: 100%; } }
  .popup__left p {
    font-size: 14px;
    color: #585858; }
  .popup__left .btn {
    margin-top: 20px; }

.popup__title {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 18px;
  position: relative;
  max-width: 300px;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #002219; }
  .popup__title::before {
    position: absolute;
    bottom: -24px;
    left: -40px;
    width: 119px;
    height: 6px;
    content: '';
    background-color: #ffd400; }

.popup__right {
  width: 50%;
  background-image: url("../img/popup-bg.jpg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: cover; }
  @media screen and (max-width: 700px) {
    .popup__right {
      display: none; } }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/slick/slick-ajax-loader.gif") center center no-repeat; }

.slick-slider {
  user-select: auto; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 18px;
  height: 28px;
  padding: 0;
  cursor: pointer;
  transform: translate(0, -50%);
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  background-image: url("../img/slick/slick-arr.png");
  background-repeat: no-repeat; }

.slick-prev {
  background-position: 0 100%; }
  .slick-prev:hover {
    background-position: 0 0; }

.slick-next {
  background-position: 100% 100%; }
  .slick-next:hover {
    background-position: 100% 0; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

.breadcrumbs {
  padding-top: 10px; }

.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }
  .breadcrumbs__list .breadcrumbs__item:last-child .breadcrumbs__link {
    text-decoration: none;
    pointer-events: none; }

.breadcrumbs__item {
  position: relative;
  list-style: none; }
  .breadcrumbs__item::after {
    font-size: 12px;
    margin: 0 7px 0 4px;
    content: '/';
    color: #fff; }
  .breadcrumbs__item:last-child::after {
    display: none; }

.breadcrumbs__link {
  font-family: "PTSans-Regular", sans-serif;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: underline;
  color: #fff; }
  .breadcrumbs__link:hover {
    text-decoration: none; }

.btn {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 15px;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0;
  padding: 10px 40px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #007458;
  border: none;
  background: none;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale; }
  .btn:hover {
    text-decoration: none;
    color: #007458; }

.button:focus {
  outline: none; }

.button > span {
  vertical-align: middle; }

/* Individual button styles */
/* Pipaluk */
.button--pipaluk::before,
.button--pipaluk::after {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  transition: transform .3s, background-color .3s;
  transition-timing-function: cubic-bezier(0.25, 0, 0.3, 1);
  border-radius: inherit; }

.button--pipaluk::before {
  border: 2px solid #ffd400; }

.button--pipaluk.button--inverted::before {
  border-color: #fff; }

.button--pipaluk::after {
  background: #ffd400; }

.button--pipaluk.button--inverted::after {
  background: #fff; }

.button--pipaluk:hover::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

.button--pipaluk::before,
.button--pipaluk:hover::after {
  -webkit-transform: scale3d(0.93, 0.7, 1);
  transform: scale3d(0.93, 0.7, 1); }

.button--pipaluk:hover::after {
  background-color: #ffd400; }

.button--pipaluk.button--inverted:hover::after {
  background-color: #fff; }

.burger {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 55px;
  height: 40px;
  cursor: pointer; }
  @media screen and (min-width: 701px) {
    .burger {
      display: none; } }

.burger input {
  display: none; }

.burger label {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  background: transparent; }

/* Exit Icon */
.burger label:before,
.burger input:checked + label:before {
  position: absolute;
  top: 50%;
  width: 35px;
  height: 3px;
  margin-top: -1px;
  content: '';
  border-radius: 2px;
  background: #007454; }

.burger label:before {
  animation: animationOneReverse 1s ease forwards; }

@keyframes animationOneReverse {
  0% {
    transform: rotate(315deg); }
  25% {
    transform: rotate(360deg); }
  50%,
  100% {
    transform: rotate(0deg); } }

.burger input:checked + label:before {
  animation: animationOne 1s ease forwards; }

@keyframes animationOne {
  0%,
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(315deg); } }

.burger label:after,
.burger input:checked + label:after {
  position: absolute;
  top: 50%;
  width: 35px;
  height: 3px;
  margin-top: -1px;
  content: '';
  border-radius: 2px;
  background: #007454; }

.burger label:after {
  animation: animationTwoReverse 1s ease forwards; }

@keyframes animationTwoReverse {
  0% {
    transform: rotate(405deg); }
  25% {
    transform: rotate(450deg); }
  50%,
  100% {
    transform: rotate(0deg); } }

.burger input:checked + label:after {
  animation: animationTwo 1s ease forwards; }

@keyframes animationTwo {
  0%,
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(450deg); }
  100% {
    transform: rotate(405deg); } }

/* Burger Icon */
.burger label .burger__icon:before {
  position: absolute;
  top: 7px;
  width: 35px;
  height: 3px;
  content: '';
  animation: animationBurgerTopReverse 1s ease forwards;
  border-radius: 2px;
  background: #007454; }

@keyframes animationBurgerTopReverse {
  0%,
  50% {
    transform: translateY(12px);
    opacity: 0; }
  51% {
    transform: translateY(12px);
    opacity: 1; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

.burger input:checked + label .burger__icon:before {
  animation: animationBurgerTop 1s ease forwards; }

@keyframes animationBurgerTop {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  50% {
    transform: translateY(12px);
    opacity: 1; }
  51%,
  100% {
    transform: translateY(12px);
    opacity: 0; } }

.burger label .burger__icon:after {
  position: absolute;
  bottom: 6px;
  width: 35px;
  height: 3px;
  content: '';
  animation: animationBurgerBottomReverse 1s ease forwards;
  border-radius: 2px;
  background: #007454; }

@keyframes animationBurgerBottomReverse {
  0%,
  50% {
    transform: translateY(-12px);
    opacity: 0; }
  51% {
    transform: translateY(-12px);
    opacity: 1; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

.burger input:checked + label .burger__icon:after {
  animation: animationBurgerBottom 1s ease forwards; }

@keyframes animationBurgerBottom {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  50% {
    transform: translateY(-12px);
    opacity: 1; }
  51%,
  100% {
    transform: translateY(-12px);
    opacity: 0; } }

.parallax {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/parallax/bg-parallax.jpg");
  background-repeat: no-repeat;
  background-position: 50%  50%;
  background-size: cover; }

.parallax__layer {
  position: absolute;
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 1024px) {
    .parallax__layer {
      display: none !important; } }

.parallax__layer-inn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 110%;
  transform: translate(-5%, 0);
  background-repeat: no-repeat;
  background-position: 50% 0; }

.parallax__layer--top .parallax__layer-inn {
  background-image: url("../img/parallax/parallax-01.png"); }

.parallax__layer--middle .parallax__layer-inn {
  background-image: url("../img/parallax/parallax-02.png"); }

.parallax__layer--bottom .parallax__layer-inn {
  background-image: url("../img/parallax/parallax-03.png"); }

.video {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url("../img/bg-video.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }

.hero-video {
  width: 100%;
  height: 100vh;
  object-fit: cover; }
  @media screen and (max-width: 700px) {
    .hero-video {
      display: none; } }

.header {
  padding: 3px 0;
  background-color: #00755a;
  z-index: 5;
  position: fixed;
  left: 0;
  right: 0;
  top: 0; }

.ticker__viewport {
  position: relative;
  overflow: hidden;
  width: 96.5%;
  max-width: 1110px;
  margin: 0 auto; }

.ticker__list {
  display: flex; }

.ticker__item {
  font-size: 13px;
  margin: 0;
  padding-right: 40px;
  cursor: pointer;
  white-space: nowrap;
  color: #fff; }

.aside-wrap {
  position: fixed;
  z-index: 10;
  top: 0;
  height: 100%;
  transition: transform .5s; }
  @media screen and (max-width: 700px) {
    .aside-wrap {
      top: 23px;
      right: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 60px;
      transition: .5s; }
      .aside-wrap.active {
        height: 100vh; } }

.aside-open {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100px;
  cursor: pointer;
  background-color: #ffd400; }
  .aside-open span {
    font-family: "PTSans-Bold", sans-serif;
    font-size: 18px;
    display: inline-block;
    transform: rotate(-90deg);
    text-transform: uppercase;
    color: #007454; }

.aside {
  position: relative;
  z-index: 10;
  display: flex;
  flex: none;
  flex-direction: column;
  width: 240px;
  min-height: 500px;
  padding: 40px 0;
  background-color: #fff;
  background-image: url("../img/bg-aside.jpg");
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: contain; }
  @media screen and (max-width: 700px) {
    .aside {
      width: 100%;
      height: 100vh;
      padding-top: 70px;
      text-align: center; } }
  .aside::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 89px;
    height: 93px;
    content: '';
    background-image: url("../img/decor-aside.jpg");
    background-repeat: no-repeat;
    background-position: 0 100%; }

.aside-logo {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 30px; }
  @media screen and (max-width: 700px) {
    .aside-logo {
      position: absolute;
      top: 15px;
      left: 20px;
      width: 151px;
      height: 34px;
      padding: 0;
      background-color: #347; } }
  .aside-logo img {
    max-width: 100%; }

.aside-menu__list {
  margin: 0;
  padding: 0; }

.aside-menu__item {
  position: relative;
  padding: 7px 20px;
  list-style: none; }
  .aside-menu__item::before {
    position: absolute;
    top: 15px;
    right: 0;
    left: 0;
    width: 100%;
    height: 6px;
    content: '';
    transition: background-color .5s;
    background-color: #fff; }
  .aside-menu__item.active::before, .aside-menu__item:hover::before {
    background-color: #007454; }
    @media screen and (max-width: 700px) {
      .aside-menu__item.active::before, .aside-menu__item:hover::before {
        background-color: transparent; } }
  @media screen and (max-width: 700px) {
    .aside-menu__item.active .aside-menu__link, .aside-menu__item:hover .aside-menu__link {
      color: #007454; } }

.aside-menu__link {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 16px;
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
  color: #002219;
  background-color: #fff; }
  .aside-menu__link:hover {
    text-decoration: none;
    color: #002219; }

.aside-menu__item--parent:hover .aside-menu__sublist {
  transform: scale(1); }

.aside-menu__sublist {
  position: absolute;
  top: 0;
  left: 100%;
  width: 240px;
  margin: 0;
  padding: 15px 20px 10px 20px;
  transition: transform .5s;
  transform: scale(0);
  background-color: #007454; }
  @media screen and (max-width: 700px) {
    .aside-menu__sublist {
      display: none !important; } }

.aside-menu__subitem {
  padding: 8px 0;
  list-style: none; }

.aside-menu__sublink {
  font-size: 14px;
  line-height: 1;
  transition: color .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff; }
  .aside-menu__sublink:hover {
    color: #ffd400; }

.aside-lang {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70px;
  height: 70px;
  margin: 70px auto 170px;
  border: 1px solid rgba(0, 118, 91, 0.3); }

.aside-lang__item {
  z-index: 2;
  flex: none;
  width: 22px;
  height: 16px;
  cursor: pointer;
  background-image: url("../img/flags.jpg"); }
  .aside-lang__item:hover + .aside-lang__item--decor {
    opacity: 1; }

.aside-lang__item--ru + .aside-lang__item--decor {
  left: -20px; }

.aside-lang__item--eng + .aside-lang__item--decor {
  right: -20px; }

.aside-lang__item--decor {
  position: absolute;
  top: 21px;
  width: 15px;
  height: 25px;
  transition: opacity .5s;
  opacity: 0;
  border: 1px solid rgba(0, 118, 91, 0.3); }

.aside-lang__item--ru {
  margin-left: -11px;
  background-position: 0 100%; }
  .aside-lang__item--ru.active {
    background-position: 0 0; }

.aside-lang__item--eng {
  margin-right: -11px;
  background-position: 100% 100%; }
  .aside-lang__item--eng.active {
    background-position: 100% 0; }

.aside-lang__text {
  font-size: 14px;
  line-height: 18px;
  width: 35px;
  height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #007454; }

.main {
  position: relative;
  padding-top: 25px; }

.index {
  min-height: calc(100vh - 30px);
  display: flex;
  align-items: center;
  padding-left: 230px; }
  @media screen and (max-width: 700px) {
    .index {
      padding-top: 60px;
      padding-left: 0; } }

.hero {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 20px 70px; }
  @media screen and (max-width: 1000px) {
    .hero {
      padding-left: 40px; } }
  @media screen and (max-width: 700px) {
    .hero {
      padding-left: 0; } }
  .hero p {
    font-size: 16px;
    color: #fff; }

.hero-text {
  position: relative; }
  .hero-text::before {
    position: absolute;
    top: -10px;
    left: -20px;
    width: 40px;
    height: 40px;
    content: '';
    border: 6px solid #ffd400; }

.hero__title {
  font-family: "PTSans-Bold", sans-serif;
  font-size: 40px;
  position: relative;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff; }
  @media screen and (max-width: 425px) {
    .hero__title {
      font-size: 30px; } }

.hero__subtitle {
  font-family: "PTSans-Bold", sans-serif;
  font-size: 27px;
  text-transform: uppercase;
  color: #fff; }
  @media screen and (max-width: 425px) {
    .hero__subtitle {
      font-size: 20px; } }

.index-btn {
  margin-top: 30px; }

.hero-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 680px;
  padding: 80px 0 30px 0; }
  @media screen and (max-width: 500px) {
    .hero-footer {
      flex-wrap: wrap;
      justify-content: space-around; } }

.hero-footer__item {
  position: relative;
  display: flex;
  align-items: center;
  flex: none;
  flex-direction: column;
  text-align: center; }
  @media screen and (max-width: 500px) {
    .hero-footer__item {
      width: 35%;
      margin-bottom: 20px; } }
  .hero-footer__item:last-child::before {
    display: none; }
  .hero-footer__item:nth-child(2)::before {
    left: 155px; }
  .hero-footer__item::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 130px;
    width: 85px;
    height: 4px;
    margin: auto;
    content: '';
    background-color: #fff; }
    @media screen and (max-width: 1000px) {
      .hero-footer__item::before {
        display: none; } }

.hero-footer__top,
.hero-footer__bottom {
  font-family: "PTSans-Regular", sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #fff; }

.hero-footer__middle {
  font-family: "PTSans-Bold", sans-serif;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 81px;
  margin-bottom: 5px;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  border: 4px solid #fff; }

.barba-container {
  padding-left: 250px; }
  @media screen and (max-width: 700px) {
    .barba-container {
      padding-top: 60px;
      padding-left: 0; } }

.page-header {
  padding-left: 20px; }
  @media screen and (max-width: 700px) {
    .page-header {
      padding-left: 0; } }

.page-header__title {
  font-family: "PTSans-Bold", sans-serif;
  font-size: 40px;
  position: relative;
  text-transform: uppercase;
  color: #fff; }
  .page-header__title::before {
    position: absolute;
    top: 13px;
    right: calc(100% + 7px);
    width: 100px;
    height: 29px;
    content: '';
    background-image: url("../img/page-header-title.png");
    background-repeat: repeat-x;
    background-position: 100% 50%; }

.page {
  position: relative; }
  .page::after {
    position: absolute;
    top: 50px;
    right: -25px;
    bottom: -40px;
    left: -160px;
    content: '';
    pointer-events: none;
    border: 2px solid #ffd400; }
    @media screen and (max-width: 1000px) {
      .page::after {
        right: -10px; } }
    @media screen and (max-width: 700px) {
      .page::after {
        left: -10px; } }
    @media screen and (max-width: 425px) {
      .page::after {
        display: none; } }

.page-inn {
  z-index: 1;
  position: relative;
  margin-bottom: 80px;
  padding: 20px 35px;
  background-color: #fff; }

.service .page-inn {
  padding: 0 0 35px 0; }

.service-tab {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px; }

.service-tab__item {
  display: flex;
  align-items: center;
  width: 33.33%;
  min-height: 90px;
  padding: 25px;
  transition: all .5s;
  border-right: 1px solid rgba(143, 143, 143, 0.3);
  border-bottom: 1px solid rgba(143, 143, 143, 0.3);
  background-color: #fff;
  box-shadow: 0 0 20px rgba(3, 116, 90, 0); }
  @media screen and (max-width: 1000px) {
    .service-tab__item {
      width: 50%; } }
  @media screen and (max-width: 550px) {
    .service-tab__item {
      width: 100%;
      min-height: 0;
      padding: 15px 25px 13px 25px; } }
  .service-tab__item:hover {
    text-decoration: none;
    background-color: #ffd400;
    box-shadow: 0 0 20px rgba(3, 116, 90, 0.1); }
    .service-tab__item:hover span {
      color: #03745a; }
  .service-tab__item:first-child {
    background-color: #03745a;
    box-shadow: inset 0 0 21px rgba(6, 70, 44, 0.38); }
    .service-tab__item:first-child span {
      font-family: "PTSans-Bold", sans-serif;
      font-size: 22px;
      letter-spacing: .22px;
      text-transform: uppercase;
      color: #fff; }
      @media screen and (max-width: 1000px) {
        .service-tab__item:first-child span {
          font-size: 20px; } }
  .service-tab__item span {
    font-family: "PTSans-Bold", sans-serif;
    font-size: 17px;
    letter-spacing: .17px;
    text-transform: uppercase;
    color: #3f3f3f; }

.service-body {
  padding: 0 40px; }

.service-body__title {
  font-family: "OpenSans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  margin-top: 30px;
  text-transform: uppercase;
  color: #00755a; }
  .service-body__title::before {
    position: absolute;
    top: 15px;
    left: -31px;
    width: 21px;
    height: 6px;
    content: '';
    background-color: #ffd400; }

#scroll2top {
  display: none; }

.scroll2top {
  position: fixed;
  right: 0;
  bottom: 20px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: auto;
  cursor: pointer;
  transition: border-color .5s;
  transform: translate(650px);
  border: 2px solid #fff; }
  @media screen and (max-width: 1400px) {
    .scroll2top {
      transform: translate(0);
      right: 20px;
      bottom: 20px;
      left: auto;
      border: 2px solid #ffd400; } }
  @media screen and (max-width: 1180px) {
    .scroll2top {
      right: 5px;
      bottom: 40px; } }
  @media screen and (max-width: 1000px) {
    .scroll2top {
      width: 40px;
      height: 40px;
      right: 20px; } }
  @media screen and (max-width: 700px) {
    .scroll2top {
      right: 10px; } }
  .scroll2top:hover {
    border-color: #ffd400; }
    .scroll2top:hover svg {
      fill: #ffd400; }
  .scroll2top svg {
    width: 21px;
    height: 34px;
    transition: fill .5s;
    transform: rotate(-90deg);
    fill: #fff; }
    @media screen and (max-width: 1400px) {
      .scroll2top svg {
        fill: #ffd400; } }
    @media screen and (max-width: 1000px) {
      .scroll2top svg {
        width: 12px;
        height: 17px; } }

.about__btn-row {
  position: relative;
  display: inline-block;
  margin: 15px 0; }
  @media screen and (max-width: 1000px) {
    .about__btn-row {
      display: flex;
      align-items: center;
      flex-direction: column; } }
  .about__btn-row::before {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background-color: #ffd400; }
    @media screen and (max-width: 1000px) {
      .about__btn-row::before {
        display: none; } }

.about__btn {
  font-size: 12px;
  line-height: 12px;
  margin-right: 30px;
  margin-bottom: 15px;
  text-align: center;
  color: #fff; }
  @media screen and (max-width: 1100px) {
    .about__btn {
      margin-right: 5px; } }
  @media screen and (max-width: 1000px) {
    .about__btn {
      min-width: 250px;
      margin-right: 0; } }
  .about__btn:last-child {
    margin-right: 0; }
  .about__btn:hover {
    color: #fff; }
    .about__btn:hover::after {
      background-color: #007454; }
  .about__btn::before {
    border: 2px solid #007454; }
  .about__btn::after {
    background-color: #007454; }

.about-sec__header {
  position: relative;
  margin-bottom: 15px; }
  .about-sec__header::before {
    position: absolute;
    top: 9px;
    left: 0;
    width: 100%;
    height: 7px;
    content: '';
    background-color: #ffd400; }

.about-sec__title {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 18px;
  position: relative;
  display: inline-block;
  padding-right: 10px;
  text-transform: uppercase;
  background-color: #fff; }

.about-license__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2%;
  margin-bottom: 15px; }
  @media screen and (max-width: 1000px) {
    .about-license__list {
      margin-right: -3.33%; } }

.about-license__item {
  width: 23%;
  margin: 0 2% 15px 0;
  outline: none;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1000px) {
    .about-license__item {
      width: 30%;
      margin: 0 3.33% 15px 0; } }

.about-license__img {
  margin-bottom: 20px; }
  .about-license__img img {
    display: block;
    width: 100%; }

.about-advantages__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.about-advantages__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 25%;
  margin-bottom: 30px;
  padding: 0 15px;
  text-align: center; }
  @media screen and (max-width: 1000px) {
    .about-advantages__item {
      width: 33.33%;
      padding: 0 5px; } }
  @media screen and (max-width: 500px) {
    .about-advantages__item {
      width: 50%; } }

.about-advantages__icon {
  display: flex;
  align-items: center;
  flex: none;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border: 4px solid #00755a; }
  .about-advantages__icon svg {
    width: 32px;
    height: 32px;
    fill: #ffd400; }

.about-advantages__text span {
  font-family: "PTSans-Regular", sans-serif;
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;
  color: #02755a; }

.about-sec__slider {
  width: calc(100% - 50px);
  margin: -20px auto 10px; }

.about-sec__slider-item img {
  display: block;
  max-width: 95%; }

.about-sec__news-list {
  display: flex;
  flex-wrap: wrap; }

.about-sec__news-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33.33%;
  margin-bottom: 20px;
  padding: 0 12px;
  text-align: center;
  text-decoration: none; }
  @media screen and (max-width: 1000px) {
    .about-sec__news-item {
      width: 50%; } }
  @media screen and (max-width: 500px) {
    .about-sec__news-item {
      width: 100%; } }
  .about-sec__news-item:hover {
    text-decoration: none; }
    .about-sec__news-item:hover .about-sec__news-pic::before {
      opacity: .5; }
    .about-sec__news-item:hover .about-sec__news-pic::after {
      width: calc(100% + 30px); }

.about-sec__news-pic {
  position: relative;
  width: 95%; }
  .about-sec__news-pic::after {
    position: absolute;
    top: 15px;
    right: -15px;
    bottom: -13px;
    left: -15px;
    width: 0;
    margin: auto;
    content: '';
    transition: width .3s;
    border: 3px solid #ffd400; }
  .about-sec__news-pic::before {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: '';
    transition: opacity .5s;
    opacity: 0;
    background-color: #03745a; }
  .about-sec__news-pic img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%; }

.about-sec__news-date {
  position: relative;
  width: 100%;
  margin-bottom: 10px; }
  .about-sec__news-date span {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 12px;
    position: relative;
    z-index: 1;
    display: block;
    padding: 3px;
    color: #fff;
    background-color: #03745a; }

.about-sec__news-text {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #002219; }
  @media screen and (max-width: 1024px) {
    .about-sec__news-text {
      font-size: 12px; } }

.contacts-map {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

.page--contacts {
  display: inline-block; }
  @media screen and (max-width: 700px) {
    .page--contacts {
      display: block;
      margin: 0 auto;
      max-width: 300px; } }
  .page--contacts .page-inn {
    padding: 15px 25px 15px 15px;
    margin-bottom: 0; }
    @media screen and (max-width: 700px) {
      .page--contacts .page-inn {
        padding: 15px; } }

.contacts__title {
  position: relative;
  display: inline-block;
  height: 50px;
  margin-left: -30px;
  padding: 0 45px 0 30px;
  background-color: #ffd400; }
  @media screen and (max-width: 700px) {
    .contacts__title {
      margin: 0 -15px;
      display: block;
      text-align: center;
      padding: 0 15px; } }
  .contacts__title::before {
    position: absolute;
    top: 0;
    left: 100%;
    width: 14px;
    height: 50px;
    content: '';
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 14px solid #ffd400; }
    @media screen and (max-width: 700px) {
      .contacts__title::before {
        display: none; } }

.contacts__title--city {
  font-family: "PTSans-Regular", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 50px;
  text-transform: none;
  color: #303030; }

.contacts__row {
  display: flex;
  padding: 20px 0 0 0; }
  .contacts__row:last-child .contacts__text {
    border: none; }
  .contacts__row a {
    font-size: 16px;
    transition: color .5s;
    color: #585858; }
    @media screen and (max-width: 700px) {
      .contacts__row a {
        font-size: 14px; } }
    .contacts__row a:hover {
      text-decoration: none;
      color: #007659; }
  .contacts__row span {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    color: #585858; }

.contacts__icon {
  flex: none;
  width: 40px;
  padding-right: 15px; }
  @media screen and (max-width: 700px) {
    .contacts__icon {
      padding-right: 10px; } }
  .contacts__icon svg {
    width: 100%;
    max-width: 24px;
    height: 100%;
    max-height: 24px;
    fill: #00755a; }

.contacts__text {
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9; }

.page-inn--projects {
  padding-top: 400px;
  padding-bottom: 80px; }

.projects-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 380px; }

.projects__title {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #00755a; }
  @media screen and (max-width: 1000px) {
    .projects__title {
      font-size: 20px; } }

.projects__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.projects__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33.33%;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: center; }
  @media screen and (max-width: 1000px) {
    .projects__item {
      width: 50%; } }
  @media screen and (max-width: 500px) {
    .projects__item {
      width: 100%; } }
  .projects__item:hover {
    text-decoration: none; }
    .projects__item:hover .projects__img img {
      transform: scale(1.05); }
    .projects__item:hover .projects__img::before {
      top: 5px;
      bottom: 5px; }

.projects__img {
  position: relative;
  width: 90%;
  max-width: 208px;
  margin-bottom: 10px; }
  .projects__img::before {
    position: absolute;
    top: 20px;
    right: -8px;
    bottom: 20px;
    left: -8px;
    width: auto;
    margin: auto;
    content: '';
    transition: .3s;
    border: 3px solid #ffd400; }
  .projects__img img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    transition: transform .5s; }

.projects__img--inn {
  overflow: hidden; }

.projects__text span {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #00755a; }

.projects-popup__layer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 21, 15, 0.6); }

.projects-popup {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  display: none;
  width: 90%;
  max-width: 870px;
  transform: translate(-50%, -50%);
  background-color: #fff; }
  .projects-popup::before {
    position: absolute;
    z-index: -1;
    top: 15px;
    right: -15px;
    bottom: -15px;
    left: 15px;
    margin: auto;
    content: '';
    border: 2px solid #ffd400; }

.projects-popup__inn {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh; }

.projects-popup__top {
  position: relative;
  padding: 30px 25px 45px 25px;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover; }
  .projects-popup__top::before {
    position: absolute;
    bottom: 17px;
    left: -8px;
    width: 119px;
    height: 6px;
    content: '';
    background-color: #ffd400; }

.projects-popup__title {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 30px;
  margin: 0;
  color: #007659; }
  @media screen and (max-width: 550px) {
    .projects-popup__title {
      font-size: 24px; } }

.projects-popup__bottom {
  padding: 35px 25px;
  background-color: #fff; }
  .projects-popup__bottom p {
    font-size: 14px; }
